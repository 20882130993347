
.navbar {
    background: rgba(0, 0, 0, 0.219); 
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px; 
    border: 1px solid rgba(255, 255, 255, 0.2); 
}

@media (max-width: 768px) {
    .navbar {
        background: #141414 !important;
        backdrop-filter: none;         
        -webkit-backdrop-filter: none;  
        border: none;                  
    }
    .navbar {
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .navbar {
        background: #141414 !important; 
        backdrop-filter: none;         
        -webkit-backdrop-filter: none;
        border: none;                  
    }
    .navbar {
        padding: 10px;
    }
}

  

.logo img {
    height: 50px;
    transition: height 0.3s ease;
    cursor: pointer;
}

.menu-toggle {
    display: none;
    cursor: pointer;
}

.menu-toggle div {
    width: 25px;
    height: 3px;
    background-color: #f1f1f1;
    margin: 5px 0;
    transition: 0.4s;
}

.navbar ul {
    list-style: none;
    display: flex;
}

.navbar ul li {
    margin: 0 20px;
}

.close-menu{
    visibility:hidden;
}
.navbar ul li {
    text-decoration: none;
    color: #f1f1f1;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 15px;
    transition: color 0.3s ease;
    cursor: pointer;
}

.navbar ul li a:hover {
    color: #7fb685;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .close-menu{
        visibility:visible;;
    }
    .navbar ul {
        display: none;
        flex-direction: column;
        position: fixed;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
    
        background-color: #141414;
        box-shadow: -4px 0 10px rgba(0, 0, 0, 0.3);
        z-index: 999;
        transition: transform 0.4s ease;
        transform: translateX(100%);
    }

    .navbar ul.open {
        display: flex;
        transform: translateX(0);
    }

    .menu-toggle {
        display: block;
    }

    .navbar ul li {
        margin: 20px 0;
        text-align: center;
        cursor: pointer;
        
    }

    .navbar ul li a {
        font-size: 20px;
        font-weight: 600;
        color: #f1f1f1;
        padding: 5px 0;
        text-decoration: none;
        display: block;
        width: 100%;
        border-bottom: #ffffff 0.5px solid;
        transition: background-color 0.3s ease;
    }

    .navbar ul li a:hover {
        background-color: #7fb685;
        color: #141414;
    }

    .menu-toggle.open div:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
    }

    .menu-toggle.open div:nth-child(2) {
        opacity: 0;
    }

    .menu-toggle.open div:nth-child(3) {
        transform: rotate(-45deg) translate(6px, -6px);
    }

    .menu-toggle div {
        transition: all 0.3s ease;
    }

    body.menu-open::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 998;
        transition: opacity 0.3s ease;
        opacity: 1;
    }

    body.menu-open {
        overflow: hidden;
    }
}

@media screen and (max-width: 480px) {
    
    .logo img {
        height: 30px;
    }
}
