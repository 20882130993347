.about {

    padding: 100px 50px;
    background: linear-gradient(405deg, #141414, #426a5a);
    color: #f1f1f1;
    position: relative;
    overflow: hidden;
}

.about-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

.about-text {
    flex: 1;
    padding-right: 40px;
}

.about-text h1 {

    font-size: 50px;
    color: #827ede;
    font-weight: 700;
    margin-bottom: 20px;
    position: relative;
    animation: slideInRight 1.5s ease;
}

.about-text h1::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: #7fb685;
    margin-top: 10px;
    border-radius: 5px;
    animation: slideInRight 1.5s ease;
}

.about-text h2 {
    font-size: 30px;
    color: #7fb685;
    font-weight: 600;
    margin-bottom: 30px;
    animation: slideInRight 1.5s ease;
}

.about-text p {
    animation: slideInRight 1.5s ease;
    font-size: 18px;
    line-height: 1.8;
    color: #f1f1f1;
    max-width: 600px;
}

.about-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-image img {
    width: 100%;
    max-width: 500px;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    opacity: 0.9;
    animation: slideInLeft 1.5s ease;
}

.about-image img:hover {
    transform: scale(1.05);
    opacity: 1;
}

.background-circle {
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    animation: rotate 10s infinite linear;
}
.circle-3 {
    width: 600px;
    height: 600px;
    bottom: 450px;
    right: -200px;
}


/* Responsive Styles */
@media screen and (max-width: 768px) {
    .about-content {
        flex-direction: column;
        padding: 1%;
    }

    .about-text {
        padding-right: 0;

    }

    .circle-3 {
        width: 600px;
        height: 600px;
        bottom: 655px;
        right: -200px;
    }
    

    .about-image img {
        max-width: 250px;
        margin-bottom: 30px;
    }

    .about-text h1 {
        font-size: 40px;
    }

    .about-text h2 {
        font-size: 20px;
        line-height: 1.5;
    }

    .about-text p {
        font-size: 16px;
    }


}

@media screen and (max-width: 480px) {
    .about-text h1 {
        font-size: 32px;
    }

    .about-text h2 {
        font-size: 20px;
        line-height: 1.5;
    }

    .about-text p {
        font-size: 14px;
    }
}