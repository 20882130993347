/* Base Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}


.case-study-page {
    background: linear-gradient(405deg, #141414, #426A5A);
    padding: 0;
}

.case-study-header-1 {
    position: relative;
    background: url('../../Files/images/case-study-1.jpg') no-repeat center center/cover;
    height: 70vh;
    align-content: end;
    color: #F1F1F1;
    text-align: center;
    border-bottom: #7FB685 7px solid;
}
.case-study-header-2 {
    position: relative;
    background: url('../../Files/images/case-study-2.jpg') no-repeat center center/cover;
    height: 70vh;
    align-content: end;
    color: #F1F1F1;
    text-align: center;
    border-bottom: #7FB685 7px solid;
}
.case-study-header-3 {
    position: relative;
    background: url('../../Files/images/case-study-3.jpg') no-repeat center center/cover;
    height: 70vh;
    align-content: end;
    color: #F1F1F1;
    text-align: center;
    border-bottom: #7FB685 7px solid;
}
.case-study-header-4 {
    position: relative;
    background: url('../../Files/images/case-study-4.jpg') no-repeat center center/cover;
    height: 70vh;
    align-content: end;
    color: #F1F1F1;
    text-align: center;
    border-bottom: #7FB685 7px solid;
}

.case-study-header .overlay {
    background: rgba(20, 20, 20, 0.7);
    align-content: end;
    border-radius: 15px;
}

.case-study-header h1 {
    font-size: 2.5rem;
    color: #F1F1F1;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.case-study-header p {
    font-size: 1.2rem;
    color: #F1F1F1;
    margin-top: 10px;
    font-weight: 300;
}



.benefits h2 {
    font-size: 2.5em;
    font-weight: 600;
    color: #7FB685;
    margin-bottom: 30px;
    border-bottom: 2px solid #827EDE;
    padding-bottom: 15px;
    animation: fadeIn 1.5s ease forwards;
}

.benefits ul {
    list-style: none;
    padding: 0;
}

.benefits strong {
    color: #7FB685;
}

.benefits p {
    font-size: 1.2em;
    color: #D1D1D1;
    margin: 10px;
}

.benefits li {
    font-size: 1.2em;
    color: #D1D1D1;
    background: rgba(255, 255, 255, 0.05);
    padding: 12px 18px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.benefits li .fa {
    color: #7FB685;
}

.benefits li:hover {
    transform: translateX(10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}


.content-section {
    padding: 60px 15px;
}

.content-container {

    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
}


.content-card {
    border-radius: 15px;
    padding: 30px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.case-title-section h2 {
    font-size: 2.5em;
    font-weight: 600;
    color: #F1F1F1;

    margin: 0%;
    background-color: #050505b4;
    border-bottom: 2px solid #7FB685;
    padding: 10px;
    text-align: center;
    animation: fadeIn 1.5s ease alternate;
}

.title-desc h3 {
    font-size: 1.4em;
    font-weight: 600;
    color: #F1F1F1;
    margin: 0%;
    padding: 10px;
    text-align: center;
    animation: fadeIn 1.5s ease alternate;
}

.case-title-section h5 {
    font-size: 1.3em;
    font-weight: 600;
    color: #7FB685;
    margin-bottom: 30px;

    padding-bottom: 15px;
    text-align: center;
    animation: slideInRight 1.5s ease forwards;
}

.strategy-details {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.cta-section {
    text-align: center;
    margin-top: 30px;
}

.cta-button {
    padding: 14px 35px;
    background-color: #827EDE;
    color: #fff;
    border-radius: 50px;
    font-size: 1.1rem;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
    background-color: #7FB685;
    transform: translateY(-3px);
}




.additional-content h3 {
    font-size: 2em;
    font-weight: 600;
    color: #7FB685;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 2px solid #827EDE;
}



.additional-content p {
    font-size: 1.1em;
    color: #F1F1F1;
    line-height: 1.6;
    margin-bottom: 15px;
}

.additional-content ul {
    list-style: none;
    padding: 0;
}

.additional-content li {
    font-size: 1.1em;
    color: #D1D1D1;
    background: rgba(255, 255, 255, 0.05);
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.framework-selection {
    border-radius: 12px;
    color: #F1F1F1;
    max-width: 1200px;
    animation: fadeIn 1s ease forwards;
}

.framework-selection h2 {
    font-size: 2em;
    font-weight: 600;
    color: #7FB685;
    margin-bottom: 20px;
    text-align: left;
    border-bottom: 2px solid #827EDE;
    padding-bottom: 10px;
}

.framework-selection p {
    font-size: 1.3em;

    color: #F1F1F1;
    margin-bottom: 20px;
    text-align: left;

    padding-bottom: 10px;
}

.framework-selection h4 {
    font-size: 1.2em;
    font-weight: 600;
    color: #7FB685;
    text-align: left;
    padding-bottom: 10px;
}

.framework-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.framework-item {
    position: relative;
    background: rgba(0, 0, 0, 0.096);
    padding: 20px;
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.3s;
}

.framework-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    padding: 5px;
    border-radius: 7px;
    height: auto;
    background-color: #7FB685;
}

.framework-item:hover {
    background-color: rgba(255, 255, 255, 0.15);
    transform: translateY(-5px);
}

.framework-item h3 {
    font-size: 1.8em;
    color: #827EDE;
    margin-bottom: 10px;
}

.framework-item p {
    font-size: 1.1em;
    color: #D1D1D1;
    line-height: 1.6;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .case-title-section h2 {
        font-size: 1.5em;

    }

    .title-desc h3 {

        font-size: 1.2em;
        font-weight: 600;
        color: #F1F1F1;
        margin: 0%;
        padding: 15px;
        text-align: justify;
        animation: slideInRight 1.5s ease forwards;
    }



    .additional-content {
        padding: 20px;
    }

    .additional-content p {
        text-align: left;
    }

    .benefits li {
        font-size: 1.2em;
        color: #D1D1D1;
        background: rgba(255, 255, 255, 0.05);
        padding: 12px 18px;
        margin-bottom: 15px;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        gap: 10px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        justify-content: center;
        flex-wrap: wrap;
    }

    .benefits {
        padding: 30px;
    }

    .framework-selection {
        padding: 20px;
    }

    .framework-selection h2 {
        font-size: 1.3rem;
        line-height: 1.5;
    }

    .framework-item h3 {
        font-size: 1.5em;
    }

    .framework-item p {
        font-size: 1em;
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }


@media (max-width: 600px) {
    .case-study-header {
        height: 50vh;
    }

    .case-study-header h1 {
        font-size: 1.8rem;
    }

    .case-study-header p {
        font-size: 0.9rem;
    }

    .content-section {
        padding: 30px 30px;
    }

    .content-container {
        grid-template-columns: 1fr;
    }

    .content-card {
        padding: 20px;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.3rem;
    }

    p, ul li {
        font-size: 0.9rem;
    }

    .cta-button {
        font-size: 0.9rem;
        padding: 10px 25px;
    }
}


@media (min-width: 768px) {
    .case-study-header {
        height: 40vh;
    }

    .case-study-header h1 {
        font-size: 2.5rem;
    }

    .case-study-header p {
        font-size: 1.2rem;
    }

    .content-container {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }

    .content-card {
        padding: 25px;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.6rem;
    }

    p, ul li {
        font-size: 1rem;
    }
}