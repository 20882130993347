.testimonial-section {
  position: relative; 
  padding: 3rem 2rem;
  background: linear-gradient(10deg, #141414, #426a5a);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #F1F1F1;
  z-index: 1; 
}

.testimonial-header h2 {
  color: #F1F1F1;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 40px;
}

.slideshow-container {
  position: relative;
  margin: auto;
  max-width: 70%;
  padding: 40px;
  display: flex;
  justify-content: space-between;
}

.slide-row {
  display: flex;
  gap: 20px;
}

.mySlides {
  flex: 1;
  border-radius:  10px 0px 20px 0px;
  background: #fff;
  padding: 30px;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.mySlides q {
  font-size: 1.2rem;
  color: #827ede;
}

.author {
  color: #426a5a;
  font-weight: bold;
  margin-top: 20px;
}

.testimonial-image {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  padding: 16px;
  color: #F1F1F1;
  font-weight: bold;
  font-size: 20px;
  transition: background-color 0.3s ease;
}

.prev {
  left: -50px;
}

.next {
  right: -50px;
}

.prev:hover,
.next:hover {
  background-color: #7fb685;
  color: white;
  border-radius: 5px;
}

.dot-container {
  text-align: center;
  margin-top: 20px;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: #7fb685;
}

.dot:hover {
  background-color: #827ede;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .slideshow-container {
    max-width: 100%;
    padding: 20px;
    flex-direction: column;
  }

  .slide-row {
    flex-direction: column;
  }

  .mySlides {
    flex: none;
    margin-bottom: 20px;
  }

  .prev, .next {
    top: auto;
    bottom: -40px;
    left: 20px;
    right: 20px;
    width: calc(50% - 40px);
    text-align: center;
  }

  .prev {
    float: left;
  }

  .next {
    float: right;
  }
}


.shape-circle {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: #827ede;
  border-radius: 50%;
}

.shape-square {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: #7fb685;
}

.shape-triangle {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 35px solid #426a5a;
}


@media screen and (max-width: 480px) {
  .testimonial-header h2 {
    color: #141414;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 40px;
  }
  
  .slideshow-container {
    max-width: 100%;
    padding: 10px;
  }

  .mySlides {
    padding: 20px;
    font-size: 0.9rem;
  }

  .next {
    display: none;
  }

  .prev {
    display: none;
  }
}
