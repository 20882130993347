
#services {
    background-image: linear-gradient(to right,  #7FB685 0%,  #827EDE 100%);
    background-size: cover;
    background-position: center;
    padding: 60px 0;
    position: relative;
    overflow: hidden;
}

.container {
    width: 85%;
    margin: 0 auto;
    text-align: center;
}

.header h1 {
    color: #141414;
    font-size: 2.8em;
    margin-bottom: 40px;
    line-height: 1.3;
    font-weight: 700;
}
.header h3 {
    color: #141414; 
    font-size: 1.6em;
    margin-bottom: 40px;
    line-height: 1.3;
    font-weight: 700;
}

.services {
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    gap: 30px;
}

.service {
    background: rgba(255, 255, 255, 0.1); 
    border-radius: 80px;
    padding: 30px;
    width: calc(33% - 40px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px); 
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 1;
    opacity: 0;
    transform: translateY(30px); 
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    visibility: hidden;
}

@keyframes slide-in-left {
    0% {
      transform: translateX(-200%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-in-right {
    0% {
      transform: translateX(200%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-in-top {
    0% {
      transform: translateY(-200%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-in-bottom {
    0% {
      transform: translateY(200%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes scale-in-center {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  

  .service:nth-child(1) {
    animation-name: slide-in-left;
    animation-delay: 0.2s;
    visibility: visible;
  }
  
  .service:nth-child(2) {
    animation-name: slide-in-top;
   
    animation-delay: 0.2s;
    visibility: visible;
  }
  
  .service:nth-child(3) {
    animation-name: slide-in-right;
    animation-delay: 0.2s;
    visibility: visible;
  }
  
  .service:nth-child(4) {
    animation-name: slide-in-bottom;
    animation-delay: 0.2s;
    visibility: visible;
  }
  
  .service:nth-child(5) {
    animation-name: slide-in-bottom;
    animation-delay: 0.2s;
    visibility: visible;
  }
  

  .service:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  


.service:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease;
}



h2 {
    color: #141414; 
    font-size: 1.6em;
    margin: 20px 0;
    font-weight: 600;
}

p {
    color: #141414; 
    line-height: 1.6;
    font-size: 1em;
}



@media (max-width: 1024px) {
    .container {
        width: 90%;
    }

    .header h1 {
        font-size: 2.2em;
    }

    .service {
        width: calc(50% - 20px);
    }
}

@media (max-width: 768px) {
    .header h1 {
        font-size: 1.8em;
    }

    .service {
        width: calc(100% - 20px);
    }

    .services {
        flex-direction: column;
        align-items: center;
    }

    .cta-button {
        font-size: 1.1em;
        padding: 12px 25px;
    }
}

@media (max-width: 480px) {
    .header h1 {
        font-size: 1.5em;
    }

    .service {
        padding: 20px;
    }

    .cta-button {
        font-size: 1em;
        padding: 10px 20px;
    }
}


.arrow-button {
    display: inline-block;
    font-size: 3em;
    color: #141414;
    text-decoration: none;

 
    transition: color 0.3s ease, transform 0.3s ease;
}

.arrow-button:hover {
 color: #141414;
    transform: translateX(5px);
}
