.case-study-section {
  position: relative; 
  padding: 3rem 2rem;
  background-image: url('../Files/images/code-bg.jpg'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #F1F1F1;
  z-index: 1; 
}


.case-study-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.7); 
  z-index: -1; 
}

.case-study-section h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #F1F1F1;

  margin-bottom: 0.5rem;
  font-weight: 700;
}

.case-study-section h2 {
  text-align: center;
  font-size: 1.5rem;
  color: #F1F1F1;

  margin-bottom: 2rem;
  font-weight: 400;
  line-height: 1.6;
}


.case-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.case-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  flex: 1 1 300px;
  max-width: 320px;
  animation: fadeIn 1.5s ease alternate;
  transition: transform 0.3s, box-shadow 0.3s;
}

.case-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}


.case-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.case-content {
  padding: 1.5rem;
  text-align: center;
}

.case-content h3 {
  margin: 0 0 1rem;
  font-size: 1rem;
  color: #426A5A;
  
  font-weight: 600;
  line-height: 1.5;
}


.case-read-more {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #827EDE;

  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.3s;
}

.case-read-more:hover {
  background-color: #7FB685;
  
  transform: scale(1.05);
}


@media (max-width: 1024px) {
  .case-card {
    max-width: 45%;
    flex-basis: 45%;
  }
}

@media (max-width: 768px) {
  .case-card {
    max-width: 90%;
    flex-basis: 90%;
  }

  .case-content h3 {
    font-size: 1rem;
  }

  .case-read-more {
    padding: 0.5rem;
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .case-card {
    max-width: 100%;
    flex-basis: 100%;
  }
}