.background-color {
  background: linear-gradient(405deg, #141414, #426A5A);
  padding: 4%;
}



.service-detail-container {
  font-family: 'Poppins', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  color: #F1F1F1;
  border-radius: 12px;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
}


.vector-icon {
  width: 408px;
  height: auto;
  margin-top: 20px;
  margin-left: 40px;

}
.service-description p {

  color: #F1F1F1;
  font-size: 1.3rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.service-header {
  display: flex;
  margin-bottom: 30px;
  border-bottom: 2px solid #7FB685;
  animation: slideInLeft 1.5s ease forwards;

}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.service-logo {
  width: 90px;
  height: auto;
  margin-bottom: 20px;
  animation: rotateLogo 2s ease-in-out infinite;
}

.service-header h1 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 2.5em;
  font-weight: 700;
  color: #827EDE;
  margin: 0;
  text-align: center;
  letter-spacing: 1.5px;
  line-height: 1.3;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}


/* Timeline Styles */
.timeline {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px 0;
  list-style-type: none;
}

.timeline-item {
  position: relative;
  margin: 20px 0;
  padding-left: 39px;
  border-left: 2px solid #7FB685;
  ;
}

.timeline-item::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #827EDE;
  border-radius: 50%;
  left: -6px;
  top: 0;
}

.timeline-content {
  padding: 15px 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-left: 20px;
  transition: background-color 0.3s, transform 0.3s;
}

.timeline-content:hover {
  background-color: #f1f1f12d;
  transform: translateX(5px);
}

.timeline-title {
  margin: 0;
  font-size: 20px;
  color: #7FB685;
  font-weight: bold;
}

.timeline-description {
  margin: 5px 0 0;
  color: #f1f1f1;
  font-size: 16px;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .service-description p {
    color: #F1F1F1;
    font-size: 1rem;
  }

  .vector-icon {
    width: 260px;
    height: auto;
    margin-top: 20px;
    margin-left: 40px;

  }

  .service-detail-container {
    padding: 20px;

  }

  .service-header {
    display: flex;

    animation: slideInLeft 1.5s ease forwards;
    align-items: flex-start !important;

  }

  .service-logo {
    width: 70px;
  }

  .service-header h1 {
    font-size: 1.9em;
    font-weight: 600; 
  }

  .timeline {
    padding: 10px 0;
  }

  .timeline-item {
    padding-left: 20px;
    border-left-width: 1px;
  }

  .timeline-item::before {
    left: -4px;
    width: 10px;
    height: 10px;
  }

  .timeline-content {
    margin-left: 10px;
    padding: 10px;
    font-size: 14px;
  }

  .timeline-title {
    font-size: 18px;
  }

  .timeline-description {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .service-header h1 {
    font-size: 1.5em;
  }

  .timeline-item {
    padding-left: 10px;
    border-left-width: 1px;
  }

  .timeline-item::before {
    left: -3px;
    width: 8px;
    height: 8px;
  }

  .timeline-content {
    margin-left: 5px;
    padding: 8px;
  }

  .timeline-title {
    font-size: 16px;
  }

  .timeline-description {
    font-size: 12px;
  }

  .technologies-group ul {
    flex-direction: column;
  }

  .technologies-group li {
    font-size: 1em;
    padding: 10px;
  }

  .benefits li {
    font-size: 1em;
    padding: 10px;
  }
}

/* Technologies Section */
.technologies h2 {
  font-size: 2.5em;
  font-weight: 600;
  color: #7FB685;
  margin-bottom: 30px;
  border-bottom: 2px solid #827EDE;
  padding-bottom: 15px;
}

.technologies-group {
  margin-bottom: 30px;
  animation: slideInRight 1.5s ease forwards;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.technologies-group h3 {
  font-size: 1.6em;
  font-weight: 500;
  color: #F1F1F1;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.technologies-group h3 .fa {
  color: #7FB685;
  font-size: 1.5em;
}

.technologies-group ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.technologies-group li {
  font-size: 1.2em;
  color: #D1D1D1;
  background: rgba(255, 255, 255, 0.05);
  padding: 12px 18px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.technologies-group li .fa {
  color: #827EDE;
}

.technologies-group li:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

/* Benefits Section */
.benefits h2 {
  font-size: 2.5em;
  font-weight: 600;
  color: #7FB685;
  margin-bottom: 30px;
  border-bottom: 2px solid #827EDE;
  padding-bottom: 15px;
  animation: fadeIn 1.5s ease forwards;
}

.benefits ul {
  list-style: none;
  padding: 0;
}

.benefits strong{
  color: #7FB685;
}

.benefits li {
  font-size: 1.2em;
  color: #D1D1D1;
  background: rgba(255, 255, 255, 0.05);
  padding: 12px 18px;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.benefits li .fa {
  color: #7FB685;
}

.benefits li:hover {
  transform: translateX(10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}