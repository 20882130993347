
.footer {
    background-color: #000000;
    padding: 50px 20px;
    color: #f7f7f7;
    text-align: center;

}

.footer .content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  
}


.image.fit img {
    width: 50%;
    margin-bottom: 20px;
}

.row.links ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    display: flex;

    flex-wrap: wrap;

    justify-content: center;

}

.row.links ul li {
    margin: 0 15px;

}

.row.links ul li a {
    text-decoration: none;
    color: #e4e4e4;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}

.row.links ul li a:hover {
    color: #cfcfcf;
}

/* Social Media Icons */
.row.social ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  
}

.row.social ul li {
    margin: 0 10px;
}

.row.social ul li a {
    font-size: 20px;
    color: #f7f7f7;
}

.row.social ul li a:hover {
    color: #cfcfcf;
}

/* Address Section */
.row.address {
    margin-top: 20px;
    font-size: 14px;
    color: #bbb;
}

/* Copyright Section */
.row.copyright {
    margin-top: 10px;
    font-size: 12px;
    color: #bbb;
}

.credits {
    background-color: #000000;
    text-align: center;
    color: #a7a7a7;

}
.credits a {
   color: #85b7e6b2;
   margin: 5px;
}


@media (max-width: 768px) {
    .image.fit img {
        width: 80%;
        margin-bottom: 20px;
    }

    .row.links ul {
        flex-direction: column;
        align-items: center;
    }

    .row.links ul li {
        margin: 10px 0;
    }
}

@media (max-width: 480px) {

    .row.address,
    .row.copyright {
        font-size: 12px;
    }

    .row.social ul {
        flex-wrap: wrap;
    }

    .row.social ul li {
        margin: 5px;
    }
}