.hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    padding: 0 50px;
    background: linear-gradient(135deg, #141414, #426a5a);
    background-size: cover;
    position: relative;
    overflow: hidden;
    z-index: 1;
  
}

.hero-left {
    flex: 1;
    z-index: 2;

    padding-bottom: 20px;
  
}

.hero-left h1 {
    font-size: 64px;
    color: #827ede;
    line-height: 1.2;
    font-weight: 700;
    animation: slideInLeft 1.5s ease;
}

.hero-left h2 {
    font-size: 30px;
    color: #7fb685;
    margin: 20px 0;
    line-height: 1.5;
    font-weight: 400;
    animation: slideInLeft 1.5s ease;
}

.hero-left p {
    font-size: 22px;
    color: #f1f1f1;
    line-height: 1.8;
    margin-bottom: 30px;
    max-width: 550px;
    animation: slideInLeft 1.5s ease;
}

.hero-left .cta-button {
    padding: 12px 30px;
    background-color: #7fb685;
    color: #141414;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    animation: fadeIn 1.5s ease alternate;
    transition: background-color 0.3s ease;
}

.hero-left .cta-button:hover {
    background-color: #426a5a;
}

.hero-right {
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
       animation: slideInRight 1.5s ease;
    align-items: center;
}

.hero-right img {
    width: 100%;
    max-width: 600px;
       animation: slideInRight 1.5s ease;
    animation: float 3s ease-in-out infinite;
}

.background-circle {
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    animation: rotate 10s infinite linear;
}



.floating-logo {
    position: absolute;
    opacity: 0.8;
    animation: fadeIn 1.5s ease alternate, float 3s ease-in-out infinite;
}

.logo-js {
    top: 25%;
    left: 55%;
    width: 100px;

}

.logo-python {
    top: 70%;
    left: 76%;

}

.logo-html {
    top: 70%;
    left: 60%;

}

.logo-css {
    top: 50%;
    left: 54%;
    width: 60px;
}

.logo-c {
    top: 50%;
    left: 90%;
    width: 60px;
}


@media screen and (max-width: 768px) {
    .floating-logo {
        width: 50px;
    }

    .logo-js {
        top: 62%;
        left: 20%;

    }

    .logo-python {
        top: 62%;
        left: 65%;
    }

    .logo-html {
        top: 75%;
        left: 10%;

    }

    .logo-css {
        top: 75%;
        left: 82%;
    }

    .logo-c {
        top: 90%;
        left: 45%;
    }
}

.circle-1 {
    width: 400px;
    height: 400px;
    top: -100px;
    left: -150px;
}

.circle-2 {
    width: 600px;
    height: 600px;
    bottom: -250px;
    right: -200px;
}


@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }
}



@media screen and (min-width: 1280px) and (max-width: 1680px) {
    .logo-js {
        top: 32%;
        left: 45%;

    }

    .logo-python {
        top: 26%;
        left: 92%;
    }

    .logo-html {
        top: 73%;
        left: 50%;

    }

    .logo-css {
        top: 73%;
        left: 92%;
    }

    .logo-c {
        top: 79%;
        left: 70%;
    }
  }
  

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .hero {
        flex-direction: column;

    }

    .hero-left {
        padding-top: 120px;


    }

    .hero-left h1 {
        font-size: 48px;
    }

    .hero-left h2 {
        font-size: 24px;
    }

    .hero-right img {
        max-width: 300px;
    }
}

@media screen and (max-width: 480px) {
    .hero-left h1 {
        font-size: 40px;
    }

    .hero-left h2 {
        font-size: 20px;
    }

    .hero-left p {
        font-size: 16px;
    }

    .hero-right img {
        max-width: 250px;
    }
}




@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  

  @keyframes scaleUp {
    0% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
  }
  

  @keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
  

  @keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
  }
  

  @keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
  }

  @keyframes slideInRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
  }
  

  @keyframes wiggle {
    0%, 100% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
    75% {
        transform: rotate(-5deg);
    }
  }
  

  @keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
  }
  
  