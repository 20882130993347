
.philosophy-section {
    position: relative;
    padding: 80px 0;
    text-align: center;
    overflow: hidden;
    background: linear-gradient(135deg, #141414 50%, #426A5A 100%);
    color: #F1F1F1;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.8) 100%);
    z-index: 1;
}

.shapes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
}

.shape-1, .shape-2, .shape-3 {
    position: absolute;
    
    border-radius: 50%;
    z-index: 0;
}


.shape-1 {
    background: rgba(129, 126, 222, 0.644);
    width: 300px;
    height: 300px;
    top: 10%;
    left: 10%;
    animation: move-1 15s linear infinite;
}

.shape-2 {
    background: rgba(127, 181, 135, 0.726);
    width: 200px;
    height: 200px;
    top: 60%;
    right: 15%;
    animation: move-2 20s linear infinite;
}

.shape-3 {
    background: rgba(255, 255, 255, 0.26);
    width: 400px;
    height: 400px;
    bottom: -10%;
    left: 50%;
    transform: translateX(-50%);
    animation: move-3 25s linear infinite;
}

@keyframes move-1 {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(50px, 50px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes move-2 {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(-50px, -50px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes move-3 {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(-100px, 100px);
    }

    100% {
        transform: translate(0, 0);
    }
}

.container {
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.content {
    position: relative;
    padding: 0 20px;
}

.philosophy-section h2 {
    font-size: 3em;
    color: #827EDE;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
    animation: fadeIn 1.5s ease alternate;
    letter-spacing: 2px;
    line-height: 1.2;
}

.philosophy-section h3 {
    font-size: 1.5em;
    color: #F1F1F1;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
    border-bottom: #F1F1F1 1px;
    letter-spacing: 2px;
    line-height: 1.2;
    animation: fadeIn 1.5s ease alternate;
}

.accent-line {
    animation: fadeIn 1.5s ease alternate;
    width: 120px;
    height: 6px;
    background: linear-gradient(90deg, #7FB685 0%, #827EDE 100%);
    margin: 20px auto;
    border-radius: 3px;
    position: relative;
    z-index: 2;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.philosophy-section p {
    font-size: 1.4em;
    line-height: 1.8;
    max-width: 800px;
    margin: 0 auto;
    color: #F1F1F1;
    position: relative;
    z-index: 2;
    animation: fade-in 1.5s ease-in-out;
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .philosophy-section h2 {
        font-size: 2.4em;
       
    }
    .philosophy-section h3{
        text-align: center;
    }

    .philosophy-section p {
        font-size: 1.2em;
        text-align: justify;
    }
}
