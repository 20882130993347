/* General Styles */
.background-color {
    background: linear-gradient(405deg, #141414, #426A5A);
    padding: 4%;
}

.service-detail-container {
    font-family: 'Poppins', sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    color: #F1F1F1;
    border-radius: 12px;
    opacity: 0;
    animation: fadeIn 1s ease forwards;
}

.service-description p {
    color: #F1F1F1;
    font-size: 1.3rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.vector-icon {
    width: 408px;
    height: auto;
    margin-top: 20px;
    margin-left: 40px;

}

.service-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    border-bottom: 2px solid #7FB685;
    animation: slideInLeft 1.5s ease forwards;
}

.service-logo {
    width: 90px;
    height: auto;
    margin-right: 20px;
    animation: rotateLogo 2s ease-in-out infinite;
}

.service-header h1 {
    font-size: 2.5em;
    font-weight: 600;
    color: #827EDE;
    margin: 0;
    text-align: left;
    letter-spacing: 1px;
}

/* Timeline Styles */
.timeline {
    position: relative;

    margin: 40px auto;
    padding: 20px 0;
    list-style-type: none;
}

.timeline h2 {
    color: #827EDE;
}

.timeline p {
    color: #F1F1F1;
    font-size: 1.2rem;
}

.timeline-item {
    position: relative;
    margin: 20px 0;
    padding-left: 39px;

}

.timeline-item::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #827EDE;
    border-radius: 50%;
    left: -6px;
    top: 0;
}

.timeline-content {
    padding: 15px 20px;
    background: rgba(0, 0, 0, 0.096);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 20px;
    transition: background-color 0.3s, transform 0.3s;
}

.timeline-content:hover {
    background-color: #3d3d3d18;
    transform: translateX(5px);
}

.timeline-title {
    margin: 0;
    font-size: 20px;
    color: #7FB685;
    font-weight: bold;
}

.timeline-description {
    margin: 5px 0 0;
    color: #f1f1f1;
    font-size: 18px;
    letter-spacing: 0.9px;
    line-height: 1.5;
}



/* Sub-Timeline Styles */

.sub-timeline {
    margin-top: 15px;

}


.sub-timeline .timeline-item {
    padding-left: 0 !important;

}

.sub-timeline .timeline-item::before {
    display: none;
    content: 'w';
    position: absolute;
    width: 10px;
    height: 10px;

    border-radius: 50%;
    left: 0px;
    top: 0;
}

.sub-timeline .timeline-title {
    color: #7FB685;
    font-size: 18px;
    font-weight: 600;
}

.sub-timeline .timeline-description {
    font-size: 14px;
    color: #e1e1e1;
}


/* Framework sec */

.framework-selection {
    border-radius: 12px;
    color: #F1F1F1;
    max-width: 1200px;
    animation: fadeIn 1s ease forwards;
}

.framework-selection h2 {
    font-size: 2em;
    font-weight: 600;
    color: #7FB685;
    margin-bottom: 20px;
    text-align: left;
    border-bottom: 2px solid #827EDE;
    padding-bottom: 10px;
}
.framework-selection p{
    font-size: 1.3em;
   
    color: #F1F1F1;
    margin-bottom: 20px;
    text-align: left;
    
    padding-bottom: 10px;
}

.framework-selection h4 {
    font-size: 1.2em;
    font-weight: 600;
    color: #7FB685;
    text-align: left;
    padding-bottom: 10px;
}

.framework-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.framework-item {
    position: relative;
    background: rgba(0, 0, 0, 0.096);
    padding: 20px !important;
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.3s;
}

.framework-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    padding: 5px;
    border-radius: 7px;
    height: auto;
    background-color: #7FB685;
}

.framework-item:hover {
    background-color: rgba(255, 255, 255, 0.15);
    transform: translateY(-5px);
}

.framework-item h3 {
    font-size: 1.8em;
    color: #827EDE;
    margin-bottom: 10px;
}

.framework-item p {
    font-size: 1.1em;
    color: #D1D1D1;
    line-height: 1.6;
    margin: 0% !important;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .framework-selection {
        padding: 20px;
    }

    .framework-selection h2 {
        font-size: 1.3rem;
        line-height: 1.5;
    }

    .framework-item h3 {
        font-size: 1.5em;
    }

    .framework-item p {
        font-size: 1em;
    }
}


/* Technologies Section */
.technologies {
    margin-top: 50px;
}

.technologies h2 {
    font-size: 2.5em;
    font-weight: 600;
    color: #7FB685;
    margin-bottom: 30px;
    border-bottom: 2px solid #827EDE;
    padding-bottom: 15px;
    animation: slideInRight 1.5s ease forwards;
}

.technologies-group {
    margin-bottom: 30px;
}

.technologies-group h3 {
    font-size: 1.6em;
    font-weight: 500;
    color: #F1F1F1;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.technologies-group ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.technologies-group li {
    font-size: 1.2em;
    color: #D1D1D1;
    background: rgba(255, 255, 255, 0.05);
    padding: 12px 18px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
}

.technologies-group li:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}


/* Additional Content Section */

.additional-content h3 {
    font-size: 2em;
    font-weight: 600;
    color: #7FB685;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 2px solid #827EDE;
}



.additional-content p {
    font-size: 1.1em;
    color: #F1F1F1;
    line-height: 1.6;
    margin-bottom: 15px;
}

.additional-content ul {
    list-style: none;
    padding: 0;
}

.additional-content li {
    font-size: 1.1em;
    color: #D1D1D1;
    background: rgba(255, 255, 255, 0.05);
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Animations */
@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}



/* Responsive Styles for Mobile */
@media screen and (max-width: 768px) {
    .service-header {
        display: flex;
        animation: slideInLeft 1.5s ease forwards;
        align-items: flex-start !important;
        flex-wrap: nowrap;
        flex-direction: column;
    }

    .service-logo {
        width: 70px;
        margin-bottom: 10px;
    }

    .vector-icon {
        width: 260px;
        height: auto;
        margin-top: 20px;
        margin-left: 40px;

    }

    .service-header h1 {
        font-size: 1.8em;
    }

    .service-description p {
        font-size: 1.1rem;
    }

    .timeline {
        padding-left: 0;
        padding-right: 0;
    }

    .timeline h2{
       font-size: 1.3em;
    }
    .timeline p{
       font-size: 1em;
    }

    .timeline-item {
        margin-left: 0;
        padding-left: 30px;
    }

    .timeline-title {
        font-size: 18px;
    }

    .timeline-description {
        font-size: 14px;
    }

    .sub-timeline .timeline-title {
        font-size: 16px;
    }

    .technologies h2,
    .benefits h2 {
        font-size: 2em;
    }

    .technologies-group h3 {
        font-size: 1.4em;
    }

    .technologies-group li {
        font-size: 1em;
    }

    .benefits li {
        font-size: 1em;
    }

    .additional-content h2 {
        font-size: 1.6em;
    }

    .additional-content h3 {
        font-size: 1.4em;
    }

    .additional-content p {
        font-size: 1em;
    }

    .additional-content li {
        font-size: 1em;
    }
}